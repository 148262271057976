$(window).load(function(){
	
	$('.slideshow').each(function(){
		var images = $(this).data('images');
		$(this).parent().find('.controls .total').html(images.length);
		$(this).height($(this).parent().find('.content').outerHeight());
		
		for(var i in images){
			var img = new Image();
			img.src = images[i];
		}
	});
	
	var moveSlideshow = function(start, direction, scope){
		
		var slideshow = $(scope).parents('.content-holder').find('.slideshow');
		var images = slideshow.data('images');
		var index = parseInt(start);
			
		var nextIndex = index+direction;
		switch(true) {
			case (nextIndex > images.length-1):
				nextIndex = 0;
				break;
			case nextIndex < 0 :
				nextIndex = images.length-1;
				break;
		}
		
		$(scope).parent().find('.position').html(nextIndex+1);			
		slideshow.css('background-image', "url('" + images[nextIndex] + "')"); 
		slideshow.data('index', nextIndex);
	};
	 
	$('.controls .button').click(function(){

		var it = 1;
		if($(this).hasClass('previous')) it = -1;
		moveSlideshow($(this).parents('.content-holder').find('.slideshow').data('index') || 0, it, this);
	});
	
	// JS Hack for bedrooms
	
	var showRoom = function(room, slider){
		var slideshow = $(slider).find('.slideshow');
		var images = slideshow.data('images');
		
		var index = 0;
		for(var i in images){
			var src = images[i];
			if( src.toLowerCase().indexOf( room ) > -1 ) {
				index = i;
				break;
			}
		}
		
		moveSlideshow(parseInt(index)-1, 1, $(slider).find('.controls .button'));
	};
	
	$('.block-slider ul li>strong').click(function(){
		var value = $(this).text();
		showRoom(value.toLowerCase(), $(this).parents('.content-holder') );
	});
});