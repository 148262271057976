// Event Management
$(document).ready(function () {
	function ev (c, a, l, v) {
		l = l || '';
		v = v || 1;
		if (ga) ga('send', 'event', c, a, l, v);
	}

	$('a[href*="mailto:"]').click(function () {
		ev('Contact', 'click', 'E-mail', 1);
	});
	$('a[href*="tel:"]').click(function () {
		ev('Contact', 'click', 'E-mail', 1);
	});
});


// Scroll effect / { reset: true }

window.sr = ScrollReveal();

sr.reveal('.effect');
sr.reveal('footer');

$(document).ready(function(){
    $('.map-menu ul li').on('click', function(){
        $('html, body').animate({scrollTop : 0},800);
    return false;
    }); 
});

// Resize height calculator

var resizeCaptionImages = function(){
	return $('.adapt-size').each(function(){
		$(this).height( $(this).parents('section').height() );  	
  	});
};

$(document).ready(resizeCaptionImages);
$(window).resize(resizeCaptionImages);
$(window).load(resizeCaptionImages);


// Background of  Landing page Effect / Via plugin (in /dist/js/slideshow.js)

$(document).ready(function(){
	
	if($('.landing').length >= 1) {
		var slides = $('.landing').data('slides');
		$(".landing .background").backstretch(slides, {duration: 4000, fade: 1250});
	}
	
}); 


 
// Scroll to ID / #removal

$(function() {
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top - $('header').height()
        }, 1500);
        return false;
      }
    }
  });
});


//Open Burger menu

$('.burger-menu, .menu .close').click(function(){
    $('.menu').toggleClass("open");
    $('body').toggleClass("translate");
});


/// WEATHER

$(document).ready(function() {
  $.simpleWeather({
    location: 'Sotogrande, ES',
    woeid: '',
    unit: 'c',
    success: function(weather) {
      html = '<h2><i class="icon-'+weather.code+'"></i> '+weather.temp+'<span>&deg;C</span>'+'</h2>';
      // html += '<ul><li>'+weather.city+', '+weather.region+'</li>';
      html += '<li class="currently">'+weather.currently+'</li>';
      html += '<li>'+weather.wind.direction+' '+weather.wind.speed+' '+weather.units.speed+'</li></ul>';
  
      $("#weather").html(html);
    },
    error: function(error) {
      //$("#weather").html('<p>'+error+'</p>');
    }
  });
});

 
//Button click effect / MATERIAL

//Github Repo is here: https://github.com/lopeax/material-button-click

$(document).ready(function() {
    var $click = $('.clickhere');
    $click.mbClicker({
        size: 300, //Maximum size of circle
        speed: 750, //Time of animation in miliseconds
        colour: 'rgba(255,255,255,0.5)', //Colour of circle and shadow
        shadow: false, //Whether or not to have a shadow on the circle
        buttonAnimation: true //Only use if button doesn't have a style attribute
    });
});


// SCroll

if( $('.js-parallax').length > 0 ) {

	var cover = document.querySelector('.js-parallax'),
	  coverHeight = Math.round(cover.offsetHeight),
	  translate,
	  parallaxThreshold = 3;
	
	var parallax = function() {
	  if (window.scrollY < coverHeight) {
	    translate = Math.round(window.scrollY / parallaxThreshold);
	    window.requestAnimationFrame(function () {
	      cover.style.transform = 'translateY('+'-'+ translate + 'px)';
	    });
	  }
	};
	
	parallax();
}

window.addEventListener('scroll', parallax, false);

window.addEventListener('resize', debounce(function () {
  coverHeight = Math.round(cover.offsetHeight);
}, 500));

function debounce(fn, wait) {
  var timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      fn.apply(this, arguments);
    }, (wait || 1));
  };
}

// MAP 

var targeted = '36.288527, -5.269696';
var sotogrande = '36.2881134, -5.2948146';

function initMap() {
    var directionsService = new google.maps.DirectionsService();
    var directionsDisplay = new google.maps.DirectionsRenderer();
    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 14,
        center: sotogrande,
        scrollwheel: false

    });
    directionsDisplay.setMap(map);

    calculateAndDisplayRoute(directionsService, directionsDisplay);

    var onChangeHandler = function() {
        calculateAndDisplayRoute(directionsService, directionsDisplay);
    };
        
    $('.map-menu ul li').click(function(){
        targeted = $(this).attr('value');
        $('.map-menu ul li').removeClass('active');
        $(this).addClass('active');
        calculateAndDisplayRoute(directionsService, directionsDisplay);
    });


    // var marker = new google.maps.Marker({
    //     position: sotogrande,
    //     map: map
    // });
}




// Draw MAP Direction

function calculateAndDisplayRoute(directionsService, directionsDisplay) {

    directionsService.route({
        origin: sotogrande,
        destination: targeted,
        travelMode: 'DRIVING'
        }, function(response, status) {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
          } else {
            window.alert('Directions request failed due to ' + status);
          }
        });
}

//

$(document).ready( function(){
	$('#contact_form input, #contact_form textarea').focus(function(){
		$(this).removeClass('error_warning');
	});
	
	$('#contact_form').submit(function(evt){
		
		var error = false;
		
		$(this).find('input').each(function(){
			if($(this).val().length <= 0 ){
				$(this).addClass('error_warning');
				error = true;
			}
		});
		
		if(error){
			evt.preventDefault();
			return false;
		}
		
		return true;
	});
	
	var thanksmodal = $('.message_thanks');
	if( thanksmodal.length > 0 ) {
		thanksmodal.fadeIn();
	}
	
	$('.message_thanks .close').click(function(){
		$('.message_thanks').fadeOut();
	});
	
});

